
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator'
import { IPrivateDonationForm, IPrivateDonationFormErrors, IExploreProjectDetail } from '@/types/projects'
import { IProjectDetailSettings } from '@/types/cms'
import { IPaymentProvider, ICoFunding } from '@/types/finances'
import PrivateDonationForm from './PrivateDonationForm.vue'
import CurrencyInput from '@/components/explore/CurrencyInput.vue'
import EmailInput from './EmailInput.vue'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import ToastMixin from '@/mixins/ToastMixin'
import LocaleMixin from '@/mixins/LocaleMixin'
import { IExploreOrganizationDetail } from '@/types/organizations'

@Component({
  name: 'private-donation-area',
  components: {
    PrivateDonationForm,
    CurrencyInput
  }
})
export default class PrivateDonationArea extends Mixins(ToastMixin, LocaleMixin) {
  @Prop() project: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop() projectDetailSettings!: IProjectDetailSettings
  @Prop({ default: () => [] }) coFundings!: ICoFunding[]
  @Prop({ required: false }) orgaColor: string
  @Prop({ default: false }) isOrganization!: boolean

  showManualBankTransferModal = false
  amountValid: boolean = null
  requestingPayment = false
  foerderAppshowForm = false
  formErrors: IPrivateDonationFormErrors = {
    amount: '',
    first_name: '',
    last_name: '',
    email: '',
    street: '',
    city: '',
    country: '',
    postal_code: '',
    additional_information: '',
    terms_accepted: '',
    privacy_accepted: ''
  }

  privateDonationForm: IPrivateDonationForm = {
    term_of_address: 1,
    academic_title: 1,
    amount: null,
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    street: '',
    city: '',
    country: 'DE',
    postal_code: '',
    additional_information: '',
    terms_accepted: false,
    privacy_accepted: false,
    needs_receipt: false,
    takes_fee: false,
    anonymus_in_info_mail: null
  }

  requiredFields = ["first_name", "last_name", "street", "city", "postal_code", "country"]
  paymentProviders: IPaymentProvider[] = []

  get customClass () {
    return this.projectDetailSettings.enableFoerderApp ? "border-less-card" : "shadow-sm"
  }

  get showDonationForm (): boolean {
    if (this.projectDetailSettings.enableFoerderApp) {
      return this.foerderAppshowForm
    }
    return !!this.privateDonationForm.amount
  }

  get amountInCents (): number {
    if (!this.privateDonationForm.amount) return 0
    return Math.round(100 * this.privateDonationForm.amount)
  }

  get coFundingDonationIncreaseInCents (): number {
    if (!this.coFundings.length || !this.amountInCents) {
      return 0
    }
    return this.coFundings.map(coFunding => {
      if (!coFunding.budget_left.in_cents) {
        return 0
      }
      if (coFunding.donation_min.in_cents > this.amountInCents) {
        return 0
      }
      if (coFunding.funding_value.in_cents) {
        if (this.amountInCents >= coFunding.donation_min.in_cents) {
          return Math.min(coFunding.funding_value.in_cents, coFunding.budget_left.in_cents)
        }
      } else if (coFunding.funding_percentage) {
        return Math.round(Math.min((this.amountInCents * coFunding.funding_percentage) / 100, coFunding.donation_max.in_cents))
      }
      return 0
    }).reduce((x, y) => x + y, 0)
  }

  get displayDonationBtn (): boolean {
    return !this.foerderAppshowForm && this.projectDetailSettings.enableFoerderApp && this.project.can_receive_fundings
  }

  get displayDonationForm (): boolean {
    return this.foerderAppshowForm || (!this.projectDetailSettings.enableFoerderApp && this.project.can_receive_fundings)
  }

  get donationIncreasedByCoFundingMessage (): string {
    if (this.coFundings.length) {
      return this.$gettextInterpolate(
        this.$gettext("Your donation will be increased by %{ increasedAmount }"),
        { increasedAmount: this.centsToCurrency(this.coFundingDonationIncreaseInCents) }
      )
    }
    return ''
  }

  get formHasErrors (): boolean {
    for (const key of Object.keys(this.formErrors)) {
      if (this.formErrors[key]) {
        return true
      }
    }
  }

  @Watch('privateDonationForm', { deep: true })
  onMyFormChanged (): void {
    if (this.formHasErrors) {
      this.validateInput()
    }
  }

  handleAmountInvalid () {
    this.amountValid = false
    this.formErrors.amount = ""
  }

  validateInput () {
    this.clearErrors()
    let validated = true
    const emailInput = new EmailInput()
    const validatedEmail = emailInput.validateEmail(this.privateDonationForm.email)
    if (!this.validateFoerderAppCheckBox()) {
      validated = false
      this.makeToast("danger", this.$gettext('Please select an option'), this.$gettext('Choose whether you want to remain anonymous.'))
    }
    if (!this.amountValid) {
      validated = false
      this.formErrors.amount = this.$gettext('Please enter a donation amount.')
    }
    if (!validatedEmail) {
      this.formErrors.email = this.$gettext("Please enter a valid email address")
      validated = false
    }
    this.requiredFields.forEach(fieldName => {
      const value = this.privateDonationForm[fieldName]
      if (!value) {
        this.formErrors[fieldName] = this.$gettext("This field is required")
        validated = false
      }
    })
    return validated
  }

  validateFoerderAppCheckBox (): boolean {
    if (this.projectDetailSettings.enableFoerderApp && this.privateDonationForm.anonymus_in_info_mail === null) {
      return false
    }
    return true
  }

  validateTermsAndPrivacy (): boolean {
    let isValid = true
    if (this.projectDetailSettings.showTermsOfUseCheckbox && !this.privateDonationForm.terms_accepted) {
      this.formErrors.terms_accepted = this.$gettext("Please accept the terms of use")
      isValid = false
    }
    if (this.projectDetailSettings.showPrivacyAgreementCheckbox && !this.privateDonationForm.privacy_accepted) {
      this.formErrors.privacy_accepted = this.$gettext("Please accept the privacy policy")
      isValid = false
    }
    return isValid
  }

  donate (paymentProvider: IPaymentProvider): void {
    if (!this.validateInput() || !this.validateTermsAndPrivacy()) {
      return
    }
    this.requestingPayment = true
    const valueWithFees = this.getNetValueWithFeesInCents(
      this.amountInCents,
      paymentProvider
    )

    const data = {
      company: this.privateDonationForm.company,
      term_of_address: this.privateDonationForm.term_of_address,
      academic_title: this.privateDonationForm.academic_title,
      project: this.project.slug,
      net_value_in_cents: this.amountInCents,
      amount_in_cents: valueWithFees,
      first_name: this.privateDonationForm.first_name,
      last_name: this.privateDonationForm.last_name,
      email: this.privateDonationForm.email,
      street: this.privateDonationForm.street,
      city: this.privateDonationForm.city,
      country: this.privateDonationForm.country,
      postal_code: this.privateDonationForm.postal_code,
      additional_information: this.privateDonationForm.additional_information,
      terms: this.privateDonationForm.terms_accepted,
      privacy: this.privateDonationForm.privacy_accepted,
      needs_receipt: this.privateDonationForm.needs_receipt,
      quantity: 1,
      currency_code: "EUR",
      provider: paymentProvider.slug,
      takes_fee: this.privateDonationForm.takes_fee,
      extra_data: localStorage.getItem("donation_extra_data") || "",
      promoter: null,
      anonymus_in_info_mail: this.privateDonationForm.anonymus_in_info_mail
    }
    if (this.isOrganization) {
      data.promoter = this.project.slug
    } else {
      data.promoter = this.project.organization.slug
    }

    axios.post(API_URLS.PRIVATE_PAYMENT_REQUESTS.DONATE, data).then(response => {
      this.requestingPayment = false
      window.location = response.data.payment_url
    }).catch(error => {
      this.requestingPayment = false
      if (error.response && error.response.data) {
        if (!Array.isArray(error.response.data)) {
          this.makeToast("danger", this.$gettext('Whoops!'), this.$gettext('an error occurred'))
          return
        }
        Object.entries(error.response.data).forEach(entry => {
          const [key, value] = entry
          const errorMsg = Array.isArray(value) ? value[0] : value
          if (key in this.formErrors) {
            this.formErrors[key] = errorMsg
          } else {
            this.makeToast("danger", key, errorMsg)
          }
        })
      }
    })
  }

  donateWithManualBankTransfer () {
    if (!this.validateInput() || !this.validateTermsAndPrivacy()) {
      return
    }
    const data = {
      project: this.project.slug,
      amount_in_cents: this.amountInCents,
      first_name: this.privateDonationForm.first_name,
      last_name: this.privateDonationForm.last_name,
      email: this.privateDonationForm.email,
      street: this.privateDonationForm.street,
      city: this.privateDonationForm.city,
      country: this.privateDonationForm.country,
      postal_code: this.privateDonationForm.postal_code,
      additional_information: this.privateDonationForm.additional_information,
      terms: this.privateDonationForm.terms_accepted,
      privacy: this.privateDonationForm.privacy_accepted,
      needs_receipt: this.privateDonationForm.needs_receipt,
      quantity: 1,
      currency_code: "EUR"
    }
    axios.post(API_URLS.MANUAL_BANK_TRANSFER.DONATE, data).then(() => {
      this.requestingPayment = false
      this.foerderAppshowForm = false
      this.showManualBankTransferModal = true
    }).catch(error => {
      this.requestingPayment = false
      if (error.response && error.response.data) {
        if (!Array.isArray(error.response.data)) {
          this.makeToast("danger", this.$gettext('Whoops!'), this.$gettext('an error occurred'))
          return
        }
        Object.entries(error.response.data).forEach(entry => {
          const [key, value] = entry
          const errorMsg = Array.isArray(value) ? value[0] : value
          if (key in this.formErrors) {
            this.formErrors[key] = errorMsg
          } else {
            this.makeToast("danger", key, errorMsg)
          }
        })
      }
    })
  }

  get errorStateAmount (): boolean {
    return this.amountValid ? true : !this.formErrors.amount
  }

  getNetValueWithFeesInCents (value: number, paymentProvider: IPaymentProvider): number {
    if (this.privateDonationForm.takes_fee) {
      return Math.round(
        (value + parseFloat(paymentProvider.fee_base_in_cents)) / (1.0 - parseFloat(paymentProvider.fee_factor))
      )
    } else {
      return value
    }
  }

  clearErrors () {
    for (const key in this.formErrors) {
      this.formErrors[key] = ""
    }
  }

  mounted () {
    this.$wait.start('load payment providers')
    axios.get(API_URLS.EXPLORE.PAYMENT_PROVIDERS).then(response => {
      this.paymentProviders = response.data.results
      this.$wait.end('load payment providers')
    })
    this.privateDonationForm.takes_fee = this.projectDetailSettings.forceTakeFee || this.projectDetailSettings.takeFeeCheckboxDefaultValue
  }
}


import { Component, Vue } from 'vue-property-decorator'
@Component
export default class DonationEmailInfoCheckbox extends Vue {
  accepted = null
  declined = null

  accept (value: boolean) {
    if (value) {
      this.declined = null
      this.accepted = true
      this.$emit("change", true)
    } else {
      this.accepted = null
      this.$emit("change", null)
    }
  }

  decline (value: boolean) {
    if (value) {
      this.accepted = null
      this.declined = true
      this.$emit("change", false)
    } else {
      this.declined = null
      this.$emit("change", null)
    }
  }
}
